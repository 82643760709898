<template>
    <div class="update">
            <img src="../../assets/images/update.png" class="update-img">

            <div class="update-title">云楚新能源</div>
            <div class="update-tag">请前往下载最新版本开启云楚新能源生活</div>
            <!-- <div class="update-text">备注：如若不能正常跳转请复制链接到浏览器下载</div> -->

            <img src="../../assets/images/update-bk.png" class="update-bkimg">

            <div class="btn-cen">
                <div class="btn" @click="ios">
                    <img src="../../assets/images/update-apple.png" class="btn-apple">
                    <div class="btn-text">iPhone下载</div>
                </div>
                <div class="btn" @click="android">
                    <img src="../../assets/images/update-an.png" class="btn-an">
                    <div class="btn-text">Android下载</div>
                </div>
            </div>

            <div class="bottom-text">
                <div class="header">品质生活在云端</div>
                <div class="text">最终解释权归云楚新能源所有</div>
            </div>

            <div v-show="show" class="show">请点击右上角打开浏览器下载!</div>


    </div>    
</template>

<script>
export default {
    data(){
        return{
            show:false
        }
    },
    
    methods:{
        android(){
            location.href = 'http://dhmall-app-v2.keplerlab.cn/appApk/dhmall.apk'
        },
        ios(){
           
            if(this.is_weixn()){
             
                this.show = true

            }else{
                location.href = 'itms-apps://itunes.apple.com/cn/app/id1474600618'
            }

                setTimeout(()=>{
                    this.show = false
                    console.log(9899);
                },2000)
            // https://itunes.apple.com/cn/app/id1474600618

        },
           is_weixn() {
          var ua = navigator.userAgent.toLowerCase();
          if (ua.match(/MicroMessenger/i) == "micromessenger") {
            return true;
          } else {
            return false;
          }
        },
    }
}
</script>

<style lang='less' scoped>
    .update{
        position: fixed;
        top: 0;
        left: 0;
        width: 100vw;
        height: 100vh;
        box-sizing: border-box;
        z-index: 99999;
        background-color: #fff;
        .update-img{
            width: 5.17rem /* 517/100 */;
            height: 4.52rem /* 452/100 */;
            margin-top: 1.04rem /* 104/100 */;
            margin-left: 1.09rem /* 109/100 */;
        }
        .update-title{
            font-size: .46rem;
            font-family: PingFang SC;
            font-weight: bold;
            color: #DF3232;
            text-align: center;

            margin-top: .2rem;
        }
        .update-tag{
            font-size: .3rem;
            font-family: PingFang SC;
            font-weight: bold;
            color: #DF3232;
            text-align: center;
            margin-top: .2rem;
        }
        .update-text{
            font-size: .22rem;
            font-family: PingFang SC;
            font-weight: 400;
            color: #AEAEAE;
            text-align: center;
            margin-top: .38rem;
        }
        .update-bkimg{
            position: fixed;
            bottom: 0;
            left: 0;
            width: 100%;
        }

        .btn-cen{
            position: fixed;
            bottom: 1.9rem;
            left: 1rem;
            display: flex;
            .btn{
                width: 2.48rem;
                height: .7rem;
                border: 2px solid #FFFFFF;
                border-radius: 10px;

                font-size: .26rem;
                font-family: PingFang SC;
                font-weight: bold;
                color: #FEFEFE;

                margin-right: .5rem;

                display: flex;
                align-items: center;
                .btn-apple{
                    width: .44rem;
                    height: .54rem;
                    margin-left: .16rem;
                }
                .btn-an{
                    width: .41rem;
                    height: .46rem;
                    margin-left: .22rem;

                }
                .btn-text{
                    margin-left: .12rem;
                }
            }
        }
        .bottom-text{
            position: fixed;
            width: 100%;
            bottom: .64rem;

            display: flex;
            flex-direction: column;
            align-items: center;
            .header{
                font-size: .3rem;
                font-family: PingFang SC;
                font-weight: 400;
                color: #FFFFFF;
                text-align: center;
            }
            .text{
                font-size: .18rem;
                font-family: PingFang SC;
                font-weight: 400;
                color: #FFFFFF;
                text-align: center;
            }

        }
    }
    .show{
        position: fixed;
        width: 5rem;
        top: 40%;
        left: 50%;
        transform: translate(-50%);
        font-size: .3rem;
        background-color: rgba(0, 0, 0, .7);
        color: #fff;
        padding: .2rem .2rem;
        border-radius: .1rem;

        display: flex;
        justify-content: center;
        align-items: center;

        
    }
</style>